.sublime-post-card {
  margin-bottom: 20px;
  width: 250px;
  height: 300px;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;

  .sublime-post-card-image {
    width: 100%;
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40%;
    transform: scale(1, 1);
    transition: all 0.2s ease-in-out;
    opacity: 1;

    &:hover {
      transform: scale(4, 4);
      opacity: 0.5;
    }
  }
}