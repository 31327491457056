@import '~exalt3d-sublime-ui/src/assets/styles/variables.scss';

.sublime-navbar {
  background-color: $darkGrey;
  max-height: 80px;

  .offer-infos {
    p {
      color: $white;
      margin-right: 20px;
    }
  }
}