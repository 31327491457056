@import '~exalt3d-sublime-ui/src/assets/styles/variables.scss';

.sublime-messages-container {
  max-width: 300px;
  padding: 15px;
  background-color: $white;
  border: $mainGold 1px solid;

  ul {
    li.sublime-message-item {
      margin: 10px 0;
    }
  }
}