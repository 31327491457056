@import '~exalt3d-sublime-ui/src/assets/styles/variables.scss';

.sublime-menu-link {
  margin: 0 10px;
  width: 170px;

  a {
    color: $white;

    &:hover {
      color: $mainGold;
    }

    &:active {
      color: $mainGold;
    }
  }

  .sublime-menu-item {
    padding: 20px;
    color: $lightGrey;
    cursor: pointer;

    span.sublime-menu-label {
      opacity: 1;
      margin-left: 15px;
      transition: all 0.5s ease-in;
      color: $white;
    }

    span.sublime-menu-label-hidden {
      opacity: 0;
    }

    ul.sublime-submenu {
      height: 0px;
      overflow: hidden;
      font-size: 0px;
    }

    ul.sublime-submenu-active {
      height: auto;
      transition: all 0.3s ease-in;
      padding: 20px;
      font-size: 14px;

      li {
        margin-bottom: 10px;
      }

      li.sublime-submenu-item-active {
        a {
          color: $mainGold;
        }
      }
    }

    &:hover {
      background-color: $darkGrey;
    }
  }

  .sublime-menu-item-active {
    color: $mainGold;

    span.sublime-menu-label {
      color: $mainGold;
    }

    &:hover {
      background-color: $darkGrey;
    }
  }

  &:hover {
    text-decoration: none;
  }
}