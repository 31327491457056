@import '~exalt3d-sublime-ui/src/assets/styles/variables.scss';

.signup-container {
  .welcome-text {
    font-family: $title-font;
    font-size: $title-font-size;
    color: $white;
    margin: 20px 0px;
  }

  .details {
    p {
      color: $white;
      margin: 10px;
    }

    span {
      p {
        font-style: italic;
      }
    }
  }
}