.sublime-file-container {
  opacity: 0.8;
  transition: opacity 0.5s ease;
  transition: transform 0.2s ease;
  transition: height 0.2s ease;
  transform: scale(0.8);

  &:hover {
    opacity: 1;
    transform: scale(1);
  }

  .sublime-file-item {
    width: 100px;
  }
}