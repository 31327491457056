@import '~exalt3d-sublime-ui/src/assets/styles/variables.scss';

// Width/Height
.full-height-screen {
  min-height: 100vh;
}

.full-width-screen {
  min-width: 100vh;
}

.full-height {
  min-height: 100%;
}

.full-width {
  min-width: 100%;
}

// Margin/Padding
.layout-margin {
  margin: 50px 30px 0 30px;
}

.marged-top {
  margin-top: 15px;
}

.marged-bottom {
  margin-bottom: 15px;
}

.marged-left {
  margin-left: 15px;
}

.marged-right {
  margin-right: 15px;
}

.padded-top {
  padding-top: 15px;
}

.padded-bottom {
  padding-bottom: 15px;
}

.padded-left {
  padding-left: 15px;
}

.padded-right {
  padding-right: 15px;
}

// Text
.container-elipsed-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sublime-gold {
  color: $mainGold;
}

.sublime-green {
  color: $mainGreen;
}

.sublime-silver {
  color: $mainSilver;
}

.sublime-black {
  color: $mainBlack;
}

.sublime-dark-grey {
  color: $darkGrey;
}

// Links
.primary-link {
  color: $mainGreen;

  &:hover {
    color: $mainGold;
  }
}

// Cursor
.pointed {
  cursor: pointer;
}

// Visibility
.hidden {
  visibility: hidden;
  height: 0px;
  width: 0px;
}

// Shadows
.inner-shadow {
  box-shadow: -1px 2px 5px 3px rgba(156, 154, 154, 0.5) inset;
}

.boxed {
  padding: 15px;
  border: 1px solid rgba(156, 154, 154, 0.5);
}

// Arrows
.bottom-arrow {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid $darkGrey;
  opacity: 0.3;
}

// Errors
.sublime-error-text {
  color: red;
  font-size: 12px;
}

// Bootstrap
.toast {
  max-width: none;
}