.offer-container {
  cursor: pointer;
  .toast-header {
    height: 30px;
    transition: all 200ms linear;
  }

  &:hover {
    .toast-header {
      height: 60px;
    }
  }
}