@import '~exalt3d-sublime-ui/src/assets/styles/variables.scss';

.sublime-menu {
  max-width: 300px;
  min-height: 100vh;
  height: 100%;
  background-color: $darkGrey;
  transition: all 0.5s ease-in;

  .close-menu {
    padding-top: 10px;
    padding-right: 10px;

    svg {
      cursor: pointer;
    }
  }
}

.sublime-menu-close {
  width: 80px;
}

@media screen and (max-width: 700px) {
  .sublime-menu {
    position: absolute;
    z-index: 99;
  }

  .sublime-menu-close {
    position: initial;
    z-index: initial;
  }
}